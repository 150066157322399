import React, {useEffect, useState} from "react";
import styles from "./config.module.scss";
import stylesGeneric from "../../styles/generics.module.scss";
import {getCookie} from "../../methods/cookie";
import {useNavigate, useParams} from "react-router-dom";
import {HomeButton} from "../../components/HomeButton/HomeButton";
import {AppConfig, AppConfigs} from "../../interfaces/AppConfig";
import {Dropdown} from "../../components/dropdown/Dropdown";
import {
  getAdditionalKeys,
  getDescriptionText,
  getElementTypes,
  getElementMoves,
  getEventList, getFunctionTypes,
  getImagesTypes,
  getKnownVars,
  getMandatoryKeys, getOrientations,
  getParamTypes, getScalingModes,
  getSiteFunctionList,
  getVarGroup, getCodeTypes
} from "../../methods/getEvents";
import {RenameField} from "../../components/renameField/RenameField";
import {SaveButton} from "../../components/SaveButton/SaveButton";
import {validateDesign, validateURLs} from "../../methods/validateConfig";
import {fetchDesign, postDesign} from "../../methods/connector";
import {getKeyVal, getPermission, mergeConfigs, openTab, renameKey, retrieveKey} from "../../methods/helpers";
import {Background} from "../../components/background/Background";
import {LoadingOverlay} from "../../components/loadingOverlay/loading";
import {InputNumber, InputString, InputDropdown, InputCheckbox} from "../../components/inputs/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAngleLeft, faAngleRight, faAnglesLeft} from "@fortawesome/free-solid-svg-icons";
import {
  fetchBooleanAlternative,
  fetchValueAlternative,
  fillConfig,
  registerElements,
  registerTemplates
} from "../../methods/templateUtils";
import {useDebouncedCallback} from "use-debounce";

const _ = require('lodash');
let languageStore = require('../../resources/languages/us.json')

let img_perm = require(`../../resources/images/permission-icon.png`)

const permissionDict = {};

const colorBG = '#BBB';


export const ConfigEditor = () => {
  const routeParams = useParams();
  const designTag = routeParams.designTag;
  const stageName = routeParams.stageName;
  let language = window.localStorage.getItem('language') ?? 'us';

  const navigate = useNavigate();
  const jwt = getCookie('jwt');
  const [designs, setDesigns] = useState<AppConfigs>();
  const [design, setDesign] = useState<AppConfig>();
  const [designFilled, setDesignFilled] = useState<AppConfig>();
  const [pageNames, setPageNames] = useState<string[]>([]);
  const [pageRename, setPageRename] = useState<string>(stageName ?? "");
  const [timeoutView , setTimeoutView] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [missingURLs, setMissingURLs] = useState<string[]>([]);

  const imageTypes = getImagesTypes();
  const mandatoryKeys = getMandatoryKeys();
  const elementTypes = getElementTypes();
  const elementMoves = getElementMoves();
  const codeTypes = getCodeTypes();


  const recalcFilledConfig = useDebouncedCallback(() => {
    if (!stageName || !design) {
      return;
    }

    const designsValidate = _.cloneDeep(designs);
    let _t, _e;
    designsValidate!.configs[stageName] = design;
    [designsValidate.templates, _t] = registerTemplates(designsValidate);
    [designsValidate.templatesElements, _e] = registerElements(designsValidate);
    const confFilled = fillConfig(design, designsValidate.templates, designsValidate.templatesElements);

    setDesignFilled(confFilled);

  }, 1000)

  const deleteGeneralKey = (path: string) => {
    if (!design) {
      return;
    }
    if (!([undefined, null, "", 0].includes(getKeyVal(path, design)[0]))) {
      setVal(path, undefined);
    } else {
      const designClone: any = _.cloneDeep(design);
      if (design.deletedKeys?.includes(path) ?? false) {
        designClone.deletedKeys = design!.deletedKeys?.filter(el => el !== path);
      } else {
        if (design.deletedKeys === undefined) {
          designClone.deletedKeys = [];
        }
        designClone.deletedKeys!.push(path);
      }
      setDesign(designClone);
    }
  }



  const translate = (s: string, l: string) => {
    if (!languageStore[s]) {
      return s;
    }
    return languageStore[s];
  }

  const openNextPage = (e: React.MouseEvent<HTMLButtonElement>, inc: number) => {
    if (!stageName || !pageNames) {
      return;
    }
    if (inc === 0) {
      openTab(e, `/generateConfig/${designTag}`);
      return;
    }
    const ind = pageNames.indexOf(stageName);
    if (ind === -1) {
      return;
    }
    if ((ind === 0 && inc === -1) || (ind === (pageNames.length - 1) && inc === 1)) {
      return;
    }
    openTab(e, `/generateConfig/${designTag}/${pageNames[ind + inc]}`);
  }

  const getGCD = (a: number, b: number): number => {
    if (b === 0)  {
      return a;
    }
    return getGCD(b, a%b);
  }

  const getRatio = (ratioType='min') => {
    const bounds = design?.design?.backgroundBounds;
    if (!bounds) {
      return `0/0`;
    }
    let width: number | undefined;
    let height: number | undefined;
    switch (ratioType) {
      case 'min': {
        width = bounds.minWidth
        height = bounds.minHeight
        break;
      }
      case 'max': {
        width = bounds.maxWidth
        height = bounds.maxHeight
        break;
      }
      case 'current': {
        width = design?.design.backgroundSize?.width;
        height = design?.design.backgroundSize?.height;
        break;
      }
      default:
        return `0/0`;
    }
    if (!width || !height) {
      return `0/0`;
    }
    const gcd = getGCD(width, height);
    if (gcd === 0) {
      return `0/0`
    }

    let ratio = (width / (height ?? 1));
    ratio = Math.round(ratio * 100) / 100;

    return `${width/gcd}/${height/gcd} = ${ratio}`

  }

  const copyElement = (key: string) => {
    const el = design?.design?.elements?.[key];
    if (!el) {
      return;
    }
    const dict: Record<string, any> = {};
    dict[key] = el;
    navigator.clipboard.writeText(JSON.stringify(dict))
  }

  const pasteElement = async () => {
    const clipboard = await navigator.clipboard.readText();
    if (!clipboard || !design) {
      return;
    }
    try {
      const el = JSON.parse(clipboard);
      const key = Object.keys(el)[0]
      const val = el[key]
      if (key && val) {
        let currentKey = key;
        let count = 0;
        while (design?.design?.elements?.[currentKey] !== undefined) {
          count += 1;
          currentKey = `${key}_${count}`
        }
        const designClone: any = _.cloneDeep(design);
        const elements = designClone?.design.elements;
        elements[currentKey] = val;
        setDesign(designClone);
        recalcFilledConfig();
      }
    } catch (e) {
      return;
    }
  }

  const setKey = (oldKey: string, newKey: string) => {
    if (oldKey === newKey) {
      return;
    }
    const designClone: any = _.cloneDeep(design);
    const elements = designClone?.design.elements;
    if (!(elements[oldKey])) {
      return;
    }
    elements[newKey] = elements[oldKey];
    delete elements[oldKey];
    setDesign(designClone);
    recalcFilledConfig();
  }

  const setVal = (baseKey: string, val: any) => {
    const perm = getPermissionWrapper(baseKey).write;
    if (!perm) {
      return;
    }

    let designClone: any = _.cloneDeep(design);
    let objInner: any = designClone;
    if (!objInner) {
      return;
    }
    const keySplit = baseKey.split('.');
    for (const key of keySplit.slice(0, keySplit.length - 1)) {
      if (!objInner?.[key]) {
        objInner[key] = {};
      }
      objInner = objInner[key];
    }
    val = val === '' ? undefined : val;
    if (val !== undefined) {
      objInner[keySplit[keySplit.length - 1]] = val;
    } else {
      delete objInner[keySplit[keySplit.length - 1]];
    }
    setDesign(designClone);
    recalcFilledConfig();
  }

  const addNewKeyWrapper = (e: React.KeyboardEvent<HTMLInputElement>, elemName: string) => {
    if (e.key !== "Enter") {
      return
    }
    //@ts-ignore
    const newKey = e.target.value;
    addNewKey(newKey, elemName)
  }

  const addNewKey = (newKey: string, elemName: string) => {
    const perm = getPermissionWrapper(`design.elements.${elemName}.css?.${newKey}`).write;
    if (!perm) {
      return;
    }

    let designClone: AppConfig = _.cloneDeep(design);
    if (designClone?.design?.elements?.[elemName]?.css?.[newKey]) {
      return;
    }

    const key = `elements.${elemName}.css`;
    const keySplit = key.split(".");
    let objInner = designClone.design;
    for (const key of keySplit.slice(0, keySplit.length)) {
      if (!objInner?.[key]) {
        objInner[key] = {};
      }
      objInner = objInner[key];
    }

    let css = designClone.design.elements?.[elemName]?.css;
    if (!css && designClone?.design?.elements?.[elemName] !== undefined) {
      designClone.design.elements[elemName].css = {};
      css = designClone.design.elements?.[elemName]?.css;
    }
    //@ts-ignore
    css[newKey] = "";
    setDesign(designClone);
    recalcFilledConfig();
  }

  const addNewKeyButton = (id: string, elemName: string) => {
    const element = document.getElementById(id);
    if (!element) {
      return;
    }
    // @ts-ignore
    addNewKey(element.value, elemName)
  }

  const deleteKey = (elemName: string, keyName: string) => {
    const perm = getPermissionWrapper(`design.elements.${elemName}.css?.${keyName}`).write;
    if (!perm) {
      return;
    }
    let designClone: AppConfig = _.cloneDeep(design);
    if (designClone?.design?.elements?.[elemName]?.css?.[keyName] === undefined) {
      return;
    }
    if (mandatoryKeys.includes(keyName)) {
      return;
    }

    const css = designClone.design.elements?.[elemName].css;
    //@ts-ignore
    delete css[keyName];
    setDesign(designClone);
    recalcFilledConfig();
  }

  const addNewElementWrapper = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== "Enter") {
      return
    }
    //@ts-ignore
    const newKey = e.target.value;
    addNewElement(newKey)
  }

  const addNewElementButton = (id: string) => {
    const element = document.getElementById(id);
    if (!element) {
      return;
    }
    // @ts-ignore
    addNewElement(element.value)
  }

  const addNewElement = (newKey: string) => {
    const perm = getPermissionWrapper(`design.elements.${newKey}`).write;
    if (!perm) {
      return;
    }

    let designClone: AppConfig = _.cloneDeep(design);
    if (!designClone?.design?.elements) {
      return;
    }

    const elements = designClone.design.elements;
    //@ts-ignore
    elements[newKey] = {css: {position: 'fixed'}};
    setDesign(designClone);
    recalcFilledConfig();
  }

  const deleteElement = (name: string) => {
    const perm = getPermissionWrapper(`design.elements.${name}`).write;
    if (!perm) {
      return;
    }
    let designClone: AppConfig = _.cloneDeep(design);
    if (!designClone?.design?.elements) {
      return;
    }

    const elements = designClone.design.elements;
    delete elements[name];
    //@ts-ignore
    setDesign(designClone);
    recalcFilledConfig();
  }

  const renamePage = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== "Enter") {
      return;
    }
    if (!stageName || stageName === pageRename) {
      return;
    }
    let designsClone: AppConfigs = _.cloneDeep(designs);
    if (!designsClone?.configs?.[stageName]) {
      return;
    }
    designsClone.configs[pageRename] = designsClone.configs[stageName];
    delete designsClone.configs[stageName];
    renameKey(designsClone, stageName, pageRename);
    setLoading(true);
    const res = await postDesign(designsClone, jwt);
    setLoading(false);
    if (res.status !== 200) {
      return;
    }
    navigate(`../${pageRename}`, {relative: "path"});
  }

  const copyTemplate = (tempName: string) => {
    const templateStage: AppConfig = _.cloneDeep(designs?.configs?.[tempName]);
    let designClone: AppConfig = _.cloneDeep(design);
    if (!templateStage) {
      return;
    }

    mergeConfigs(designClone, templateStage);
    setDesign(designClone);
    recalcFilledConfig();
  }

  const renderSwitchSettings = (fieldType: string, funcName: string, index: number, key: string, parentProp: any, placeholder?: string) => {

    switch(fieldType) {
      case 'number':
        return <InputNumber path={`settings.${funcName}.${index}.${key}`} parentProps={parentProp}/>
      case 'string':
        return <InputString path={`settings.${funcName}.${index}.${key}`} placeholder={translate(placeholder ?? '', language)} parentProps={parentProp}/>
      case 'boolean':
        return <InputDropdown
          path={`settings.${funcName}.${index}.${key}`}
          valueList={['true', 'false']}
          shouldTranslate={placeholder}
          defaultVal={placeholder}
          parentProps={parentProps}
        />
    }
  }

  const renderSwitchElem = (fieldType: string, elemName: string, key: string, parentProp: any) => {

    switch(fieldType) {
      case 'number':
        return <InputNumber path={`design.elements.${elemName}.${key}`} parentProps={parentProp}/>
      case 'string':
        return <InputString path={`design.elements.${elemName}.${key}`} parentProps={parentProp}/>
      case 'boolean':
        return <InputDropdown
          path={`design.elements.${elemName}.${key}`}
          valueList={['true', 'false']}
          parentProps={parentProps}
        />
    }
  }

  const genFunctionParameters = () => {
    const rows = [];
    rows.push(<tr key={`hr-params`}><td><hr/></td></tr>)
    rows.push(
      <tr className={styles["table-row"]} key={`params-key`}>
        <td>
          {translate('ifKey', language)}
        </td>
        <td/>
        <td>
          <InputString placeholder={translate('ifKey', language)} path={'settings.functionParameters.key'} parentProps={parentProps}/>
        </td>
        <td/>
        <td/>
        <td/>
      </tr>
    )
    rows.push(
      <tr className={styles["table-row"]} key={`params-group`}>
        <td>
          {translate('ifGroup', language)}
        </td>
        <td/>
        <td>
          <InputDropdown
            path={"settings.functionParameters.group"}
            valueList={getVarGroup()}
            allowOther={true}
            readOnly={!getPermissionWrapper(`settings.functionParameters.group`).write}
            type={getPermissionType(`settings.functionParameters.group`, 'text')}
            shouldTranslate={'ifGroup'}
            defaultVal={'ifGroup'}
            parentProps={parentProps}
          />
        </td>
        <td/>
        <td/>
        <td/>
      </tr>
    )
    rows.push(
      <tr className={styles["table-row"]} key={`params-if-condition`}>
        <td>
          {translate('ifCondition', language)}
        </td>
        <td/>
        <td>
          <InputDropdown
            path={"settings.functionParameters.condition"}
            valueList={['>', '>=', '=', '<=', '<']}
            allowOther={false}
            readOnly={!getPermissionWrapper(`settings.functionParameters.group`).write}
            type={getPermissionType(`settings.functionParameters.condition`, 'text')}
            shouldTranslate={'ifCondition'}
            defaultVal={'ifCondition'}
            parentProps={parentProps}
          />
        </td>
        <td/>
        <td/>
        <td/>
      </tr>
    )
    rows.push(
      <tr className={styles["table-row"]} key={`params-if-value`}>
        <td>
          {translate('ifVal', language)}
        </td>
        <td/>
        <td>
          <InputString placeholder={translate('ifVal', language)} path={'settings.functionParameters.value'} parentProps={parentProps}/>
        </td>
        <td/>
        <td/>
        <td/>
      </tr>
    )

    return rows
  }


  const genSwitchParameters = () => {
    const rows = [];
    rows.push(<tr key={`hr-params`}><td><hr/></td></tr>)

    const switchParams = design?.settings.switchParameters ?? []

    switchParams.forEach((val, i) => {
      rows.push(
        <tr className={styles["table-row"]} key={`${i}-switch-value`}>
          <td>
            {translate('switchParam', language)} {i}
          </td>
          <InputString placeholder={translate('switchConditionKey', language)} path={`settings.switchParameters.${i}.switchVal`}
                       parentProps={parentProps}/>
          <td>

            <InputDropdown
              path={`settings.switchParameters.${i}.switchTarget`}
              valueList={['previousScreen', ...pageNames]}
              allowOther={false}
              readOnly={!getPermissionWrapper(`settings.switchParameters.${i}.switchTarget`).write}
              type={getPermissionType(`settings.switchParameters.${i}.switchTarget`, 'text')}
              shouldTranslate={'switchTarget'}
              defaultVal={'switchTarget'}
              parentProps={parentProps}
            />
          </td>
          <td>
            <input
              type={"button"}
              value={translate('deleteSwitch', language)}
              onClick={() => {
                const perm = getPermissionWrapper(`settings.switchParameters`).write;
                if (!perm) {
                  return;
                }
                if (!design) {
                  return;
                }
                const designClone: any = _.cloneDeep(design);
                const settings = designClone?.settings?.switchParameters ?? [];
                settings.splice(i, 1);
                designClone!.settings.switchParameters = settings;
                setDesign(designClone);
                recalcFilledConfig();

              }}
            />
          </td>
          <td/>
          <td/>
          <td/>
        </tr>
      )
    })

    rows.push(
      <tr className={styles["table-row"]} key={`$new-switch-value`}>
        <td>
          <input
            type={"button"}
            value={translate('switchNewKey', language)}
            onClick={() => {
              const perm = getPermissionWrapper(`settings.switchParameters`).write;
              if (!perm) {
                return;
              }
              if (!design) {
                return;
              }
              const designClone: any = _.cloneDeep(design);
              const settings = designClone?.settings?.switchParameters ?? [];
              settings.push({});
              designClone!.settings.switchParameters = settings;
              setDesign(designClone);
              recalcFilledConfig();

            }}
          />
        </td>
        <td/>
        <td/>
        <td/>
        <td/>
        <td/>
      </tr>
    )



    rows.push(
      <tr className={styles["table-row"]} key={`params-key`}>
        <td>
          {translate('ifKey', language)}
        </td>
        <td/>
        <td>
          <InputString placeholder={translate('switchConditionKey', language)} path={'settings.resKey'} parentProps={parentProps}/>
        </td>
        <td/>
        <td/>
        <td/>
      </tr>
    )
    rows.push(
      <tr className={styles["table-row"]} key={`params-group`}>
        <td>
          {translate('ifGroup', language)}
        </td>
        <td/>
        <td>
          <InputDropdown
            path={"settings.resGroup"}
            valueList={getVarGroup()}
            allowOther={true}
            readOnly={!getPermissionWrapper(`settings.resGroup`).write}
            type={getPermissionType(`settings.resGroup`, 'text')}
            shouldTranslate={'switchConditionGroup'}
            defaultVal={'switchConditionGroup'}
            parentProps={parentProps}
          />
        </td>
        <td/>
        <td/>
        <td/>
      </tr>
    )
    return rows
  }

  const genCopyParameters = () => {
    const rows = [];
    rows.push(<tr key={`hr-copy`}><td><hr/></td></tr>)
    const copyParams = design?.settings.copyParameters ?? []

    copyParams.forEach((val, i) => {
      rows.push(
        <tr className={styles["table-row"]} key={`${i}-copy-value`}>
          <td>
            {translate('copyParam', language)} {i}
          </td>
          <td>
            <InputDropdown
              path={`settings.copyParameters.${i}.originKey`}
              valueList={getKnownVars(design?.settings?.copyParameters?.[i]?.originGroup ?? "")}
              allowOther={true}
              readOnly={!getPermissionWrapper(`settings.copyParameters.${i}.originKey`).write}
              type={getPermissionType(`settings.copyParameters.${i}.originKey`, 'text')}
              shouldTranslate={'copyOriginKey'}
              defaultVal={'copyOriginKey'}
              parentProps={parentProps}
            />
          </td>
          <td>
            <InputDropdown
              path={`settings.copyParameters.${i}.originGroup`}
              valueList={getVarGroup()}
              allowOther={true}
              readOnly={!getPermissionWrapper(`settings.copyParameters.${i}.originGroup`).write}
              type={getPermissionType(`settings.copyParameters.${i}.originGroup`, 'text')}
              shouldTranslate={'copyOriginGroup'}
              defaultVal={'copyOriginGroup'}
              parentProps={parentProps}
            />
          </td>
          <td>
            <InputDropdown
              path={`settings.copyParameters.${i}.targetGroup`}
              valueList={getVarGroup()}
              allowOther={true}
              readOnly={!getPermissionWrapper(`settings.copyParameters.${i}.originGroup`).write}
              type={getPermissionType(`settings.copyParameters.${i}.originGroup`, 'text')}
              shouldTranslate={'copyTargetGroup'}
              defaultVal={'copyTargetGroup'}
              parentProps={parentProps}
            />
          </td>
          <td>
            <InputString placeholder={translate('copyTargetKey', language)} path={`settings.copyParameters.${i}.targetKey`} parentProps={parentProps}/>
          </td>
          <td>
            <input
              type={"button"}
              value={translate('copyDelete', language)}
              onClick={() => {
                const perm = getPermissionWrapper(`design.settings.copyParameters`).write;
                if (!perm) {
                  return;
                }
                if (!design) {
                  return;
                }
                const designClone: any = _.cloneDeep(design);
                const settings = designClone?.settings?.copyParameters ?? [];
                settings.splice(i, 1);
                designClone!.settings.copyParameters = settings;
                setDesign(designClone);
                recalcFilledConfig();

              }}
            />
          </td>
        </tr>
      )
    })
    rows.push(
      <tr className={styles["table-row"]} key={`$new-copy-value`}>
        <td>
          <input
            type={"button"}
            value={translate('copyNewKey', language)}
            onClick={() => {
              const perm = getPermissionWrapper(`design.settings.copyParameters`).write;
              if (!perm) {
                return;
              }
              if (!design) {
                return;
              }
              const designClone: any = _.cloneDeep(design);
              const settings = designClone?.settings?.copyParameters ?? [];
              settings.push({});
              designClone!.settings.copyParameters = settings;
              setDesign(designClone);
              recalcFilledConfig();

            }}
          />
        </td>
        <td/>
        <td/>
        <td/>
        <td/>
        <td/>
      </tr>
    )
    return rows
  }

  const genSetParameters = () => {
    const rows = [];
    rows.push(<tr key={`hr-copy`}><td><hr/></td></tr>)
    const setParams = design?.settings.setParameters ?? []

    setParams.forEach((val, i) => {
      rows.push(
        <tr className={styles["table-row"]} key={`${i}-copy-value`}>
          <td>
            {translate('setParam', language)} {i}
          </td>
          <td>
            {
              val?.newValueType === 'function'
              ?
                <InputDropdown
                  path={`settings.setParameters.${i}.newValue`}
                  valueList={getFunctionTypes()[0]}
                  allowOther={false}
                  readOnly={!getPermissionWrapper(`settings.setParameters.${i}.newValue`).write}
                  type={getPermissionType(`settings.setParameters.${i}.newValue`, 'text')}
                  shouldTranslate={'setNewValue'}
                  defaultVal={'setNewValue'}
                  parentProps={parentProps}
                />
              :
                renderSwitchSettings(val?.newValueType ?? "string", 'setParameters', i, 'newValue', parentProps, 'setNewValue')
            }
          </td>
          <td>
            <InputDropdown
              path={`settings.setParameters.${i}.newValueType`}
              valueList={getParamTypes()}
              allowOther={false}
              readOnly={!getPermissionWrapper(`settings.setParameters.${i}.newValueType`).write}
              type={getPermissionType(`settings.setParameters.${i}.newValueType`, 'text')}
              shouldTranslate={'setNewValueType'}
              defaultVal={'setNewValueType'}
              parentProps={parentProps}
            />
          </td>
          <td>
            <InputDropdown
              path={`settings.setParameters.${i}.targetGroup`}
              valueList={getVarGroup()}
              allowOther={true}
              readOnly={!getPermissionWrapper(`settings.setParameters.${i}.targetGroup`).write}
              type={getPermissionType(`settings.setParameters.${i}.targetGroup`, 'text')}
              shouldTranslate={'setTargetGroup'}
              defaultVal={'setTargetGroup'}
              parentProps={parentProps}
            />
          </td>
          <td>
            <InputString placeholder={translate('setTargetKey', language)} path={`settings.setParameters.${i}.targetKey`} parentProps={parentProps}/>
          </td>
          <td>
            <input
              type={"button"}
              value={translate('setDelete', language)}
              onClick={() => {
                const perm = getPermissionWrapper(`design.settings.setParameters`).write;
                if (!perm) {
                  return;
                }
                if (!design) {
                  return;
                }
                const designClone: any = _.cloneDeep(design);
                const settings = designClone?.settings?.setParameters ?? [];
                settings.splice(i, 1);
                designClone!.settings.setParameters = settings;
                setDesign(designClone);
                recalcFilledConfig();

              }}
            />
          </td>
        </tr>
      )
      if (val?.newValueType === 'function' && getFunctionTypes()[1].includes(val?.newValue?.toString() ?? "")) {
        rows.push(
          <tr className={styles["table-row"]} key={`${i}-copy-value`}>
            <td>
              {translate('setParam2', language)} {i}
            </td>
            <td>
              <InputString placeholder={translate('setInputKey1', language)} path={`settings.setParameters.${i}.inputKey1`} parentProps={parentProps}/>
            </td>
            <td>
              <InputDropdown
                path={`settings.setParameters.${i}.inputGroup1`}
                valueList={getVarGroup()}
                allowOther={true}
                readOnly={!getPermissionWrapper(`settings.setParameters.${i}.inputGroup1`).write}
                type={getPermissionType(`settings.setParameters.${i}.inputGroup1`, 'text')}
                shouldTranslate={'setInputGroup1'}
                defaultVal={'setInputGroup1'}
                parentProps={parentProps}
              />
            </td>
            <td>
              <InputString placeholder={translate('setInputKey2', language)} path={`settings.setParameters.${i}.inputKey2`} parentProps={parentProps}/>
            </td>
            <td>
              <InputDropdown
                path={`settings.setParameters.${i}.inputGroup2`}
                valueList={getVarGroup()}
                allowOther={true}
                readOnly={!getPermissionWrapper(`settings.setParameters.${i}.inputGroup2`).write}
                type={getPermissionType(`settings.setParameters.${i}.inputGroup2`, 'text')}
                shouldTranslate={'setInputGroup2'}
                defaultVal={'setInputGroup2'}
                parentProps={parentProps}
              />
            </td>
            <td>
              <input
                type={"button"}
                value={translate('setDelete', language)}
                onClick={() => {
                  const perm = getPermissionWrapper(`design.settings.setParameters`).write;
                  if (!perm) {
                    return;
                  }
                  if (!design) {
                    return;
                  }
                  const designClone: any = _.cloneDeep(design);
                  const settings = designClone?.settings?.setParameters ?? [];
                  settings.splice(i, 1);
                  designClone!.settings.setParameters = settings;
                  setDesign(designClone);
                  recalcFilledConfig();

                }}
              />
            </td>
          </tr>
        )

      }


      if (val?.newValueType === 'function' && getFunctionTypes()[2].includes(val?.newValue?.toString() ?? "")) {
        rows.push(
          <tr className={styles["table-row"]} key={`${i}-copy-value`}>
            <td>
              {translate('setParam2', language)} {i}
            </td>
            <td>
              <InputNumber path={`settings.setParameters.${i}.min`} parentProps={parentProps}/>
            </td>
            <td>
              <InputNumber path={`settings.setParameters.${i}.max`} parentProps={parentProps}/>
            </td>
            <td>
              <InputDropdown
                path={`settings.setParameters.${i}.isInt`}
                valueList={['true', 'false']}
                readOnly={!getPermissionWrapper(`settings.setParameters.${i}.isInt`).write}
                type={getPermissionType(`settings.setParameters.${i}.isInt`, 'text')}
                booleanDefault={'true'}
                shouldTranslate={'switchConditionGroup'}
                defaultVal={'switchConditionGroup'}
                parentProps={parentProps}
              />
            </td>
            <td>
            </td>
            <td/>
          </tr>
        )

      }
    })
    rows.push(
      <tr className={styles["table-row"]} key={`$new-copy-value`}>
        <td>
          <input
            type={"button"}
            value={translate('setNewKey', language)}
            onClick={() => {
              const perm = getPermissionWrapper(`design.settings.setParameters`).write;
              if (!perm) {
                return;
              }
              if (!design) {
                return;
              }
              const designClone: any = _.cloneDeep(design);
              const settings = designClone?.settings?.setParameters ?? [];
              settings.push({});
              designClone!.settings.setParameters = settings;
              setDesign(designClone);
              recalcFilledConfig();

            }}
          />
        </td>
        <td/>
        <td/>
        <td/>
        <td/>
        <td/>
      </tr>
    )
    return rows
  }


  const genCheckInternetParameters = () => {
    const rows = [];
    rows.push(<tr key={`hr-params`}><td><hr/></td></tr>)
    rows.push(
      <tr className={styles["table-row"]} key={`params-key`}>
        <td>
          {translate('pingURL', language)}
        </td>
        <td/>
        <td>
          <InputString placeholder={translate('pingURL', language)} path={'settings.pingURL'} parentProps={parentProps}/>
        </td>
        <td/>
        <td/>
        <td/>
      </tr>
    )
    return rows
  }

  const genGetParameters = () => {
    const rows = [];
    rows.push(<tr key={`hr-params`}><td><hr/></td></tr>)
    rows.push(
      <tr className={styles["table-row"]} key={`params-1-key`}>
        <td>
          {translate('getURL', language)}
        </td>
        <td/>
        <td>
          <InputString placeholder={translate('getURL', language)} path={'settings.getURL'} parentProps={parentProps}/>
        </td>
        <td/>
        <td/>
        <td/>
      </tr>
    )
    rows.push(
      <tr className={styles["table-row"]} key={`params-2-key`}>
        <td>
          {translate('getURLParams', language)}
        </td>
        <td>
          {translate('getGroup', language)}
        </td>
        <td>
          <InputString placeholder={translate('getGroup', language)} path={'settings.getGroup'} parentProps={parentProps}/>
        </td>
        <td>
          {translate('getKey', language)}
        </td>
        <td>
          <InputString placeholder={translate('getKey', language)} path={'settings.getKey'} parentProps={parentProps}/>
        </td>
        <td/>
      </tr>
    )
    return rows
  }


  const genResParameters = () => {
    const rows = [];
    rows.push(<tr key={`hr-params`}><td><hr/></td></tr>)
    rows.push(
      <tr className={styles["table-row"]} key={`params-key`}>
        <td>
          {translate('resURLParams', language)}
        </td>
        <td>
          {translate('resGroup', language)}
        </td>
        <td>
          <InputString placeholder={translate('resGroup', language)} path={'settings.resGroup'} parentProps={parentProps}/>
        </td>
        <td>
          {translate('resKey', language)}
        </td>
        <td>
          <InputString placeholder={translate('resKey', language)} path={'settings.resKey'} parentProps={parentProps}/>
        </td>
        <td/>
      </tr>
    )
    return rows
  }


  const genQRParameters = () => {
    const rows = [];
    rows.push(<tr key={`hr-params`}><td><hr/></td></tr>)
    rows.push(
      <tr className={styles["table-row"]} key={`params-1-key`}>
        <td>
          {translate('qrType', language)}
        </td>
        <td/>
        <td>
          <InputDropdown
            path={`settings.codeType`}
            valueList={codeTypes}
            readOnly={!getPermissionWrapper(`settings.codeType`).write}
            type={getPermissionType(`settings.codeType`, 'text')}
            shouldTranslate={'qrCodeType'}
            defaultVal={'qrCodeType'}
            parentProps={parentProps}
          />
        </td>
        <td/>
        <td/>
        <td/>
      </tr>
    );

    rows.push(
      <tr className={styles["table-row"]} key={`params-2-key`}>
        <td>
          {translate('qrEnableFlip', language)}
        </td>
        <td/>
        <td>
          <InputDropdown
            path={`settings.codeEnableFlip`}
            valueList={['true', 'false']}
            readOnly={!getPermissionWrapper(`settings.codeEnableFlip`).write}
            type={getPermissionType(`settings.codeEnableFlip`, 'text')}
            booleanDefault={'false'}
            shouldTranslate={'qrCodeFlip'}
            defaultVal={'qrCodeFlip'}
            parentProps={parentProps}
          />
        </td>
        <td/>
        <td/>
        <td/>
      </tr>
    );

    rows.push(
      <tr className={styles["table-row"]} key={`params-3-key`}>
        <td>
          {translate('qrFrontCamera', language)}
        </td>
        <td/>
        <td>
          <InputDropdown
            path={`settings.codeFrontCamera`}
            valueList={['true', 'false']}
            readOnly={!getPermissionWrapper(`settings.codeFrontCamera`).write}
            type={getPermissionType(`settings.codeCamera`, 'text')}
            booleanDefault={'false'}
            shouldTranslate={'qrCodeFront'}
            defaultVal={'qrCodeFront'}
            parentProps={parentProps}
          />
        </td>
        <td/>
        <td/>
        <td/>
      </tr>
    );

    rows.push(
    <tr className={styles["table-row"]} key={`params-4-key`}>
      <td>
        {translate('resURLParams', language)}
      </td>
      <td>
        {translate('resGroup', language)}
      </td>
      <td>
        <InputString placeholder={translate('resGroup', language)} path={'settings.resGroup'} parentProps={parentProps}/>
      </td>
      <td>
        {translate('resKey', language)}
      </td>
      <td>
        <InputString placeholder={translate('resKey', language)} path={'settings.resKey'} parentProps={parentProps}/>
      </td>
      <td/>
    </tr>
  )
    return rows
  }

  const genPostParameters = () => {
    const rows = [];
    rows.push(<tr key={`hr-params`}>
      <td>
        <hr/>
      </td>
    </tr>)
    rows.push(
      <tr className={styles["table-row"]} key={`params-key`}>
        <td>
          {translate('postURL', language)}
        </td>
        <td/>
        <td>
          <InputString placeholder={translate('postURL', language)} path={'settings.postURL'} parentProps={parentProps}/>
        </td>
        <td/>
        <td/>
        <td/>
      </tr>
    )
    rows.push(
      <tr className={styles["table-row"]} key={`params-key`}>
        <td>
          {translate('postGroup', language)}
        </td>
        <td/>
        <td>
          <InputString placeholder={translate('postGroup', language)} path={'settings.postGroup'} parentProps={parentProps}/>
        </td>
        <td/>
        <td/>
        <td/>
      </tr>
    )
    rows.push(
      <tr className={styles["table-row"]} key={`params-key`}>
        <td>
          {translate('postKey', language)}
        </td>
        <td/>
        <td>
          <InputString placeholder={translate('postKey', language)} path={'settings.postKey'} parentProps={parentProps}/>
        </td>
        <td/>
        <td/>
        <td/>
      </tr>
    )
    return rows
  }

  const genDownloadParameters = () => {
    const rows = [];
    rows.push(<tr key={`hr-params`}>
      <td>
        <hr/>
      </td>
    </tr>)
    rows.push(
      <tr className={styles["table-row"]} key={`params-key`}>
        <td>
          {translate('getURLData', language)}
        </td>
        <td>
          {translate('getURL', language)}
        </td>
        <td>
          <InputString placeholder={translate('getURL', language)} path={'settings.getURL'} parentProps={parentProps}/>
        </td>
        <td>
          {translate('getKey', language)}
        </td>
        <td>
          <InputString placeholder={translate('getKey', language)} path={'settings.getKey'} parentProps={parentProps}/>
        </td>
        <td/>
      </tr>
    )
    return rows
  }

  const genParseJSONParameters = () => {
    const rows = [];
    rows.push(<tr key={`hr-params`}><td><hr/></td></tr>)
    rows.push(
      <tr className={styles["table-row"]} key={`params-2-key`}>
        <td>
          {translate('getJSONParams', language)}
        </td>
        <td>
          {translate('getGroup', language)}
        </td>
        <td>
          <InputString placeholder={translate('getGroup', language)} path={'settings.getGroup'} parentProps={parentProps}/>
        </td>
        <td>
          {translate('getKey', language)}
        </td>
        <td>
          <InputString placeholder={translate('getKey', language)} path={'settings.getKey'} parentProps={parentProps}/>
        </td>
        <td/>
      </tr>
    )
    rows.push(
      <tr className={styles["table-row"]} key={`params-2-key`}>
        <td>
          {translate('resJSONParams', language)}
        </td>
        <td>
          {translate('resGroup', language)}
        </td>
        <td>
          <InputString placeholder={translate('resGroup', language)} path={'settings.resGroup'} parentProps={parentProps}/>
        </td>
        <td>
          {translate('resKey', language)}
        </td>
        <td>
          <InputString placeholder={translate('resKey', language)} path={'settings.resKey'} parentProps={parentProps}/>
        </td>
        <td/>
      </tr>
    )
    return rows
  }


  const genEvents = () => {
    if (!design?.settings.callFunction){
      return []
    }
    const events = getEventList(design?.settings.callFunction);
    const rows = [];
    if (events.length === 0) {
      return [];
    }
    rows.push(<tr key={`hr-${events}`}><td><hr/></td></tr>)
    const eventElements = events.map((ev) => {
      return <tr className={styles["table-row"]} key={ev}>
        <td>
          {translate(ev, language)}
        </td>
        <td/>
        <td>
          <InputDropdown
            path={`settings.eventTargets.${ev}`}
            valueList={['previousScreen', ...pageNames]}
            readOnly={!getPermissionWrapper(`settings.eventTargets.${ev}`).write}
            type={getPermissionType(`settings.eventTargets.${ev}`, 'text')}
            parentProps={parentProps}
          />
        </td>
        <td/>
        <td/>
        <td/>
      </tr>
    })
    return [...rows, ...eventElements];

  }

  const genElements = () => {
    const elementsRaw = designFilled?.design.elements ?? {};
    const elements = Object.entries(elementsRaw).sort((a, b) => {
      return a[0].localeCompare(b[0]);
    }
    ).map(el => {
      const elemName= el[0];
      const elemConfig = el[1];
      const rows = [];
      const availableKeysCSS = [...mandatoryKeys, ...Object.keys(elemConfig.css ?? {})].filter((v,i,a) => a.indexOf(v) === i);

      rows.push(<tr key={`hr-${el[0]}`}><td><hr/></td></tr>)
      rows.push(
        <tr className={styles["table-row"]} key={`name-${el[0]}`}>
          <td>
            {translate('elementName', language)}
          </td>
          <td/>
          <td>
            <RenameField
              path={`design.elements.${elemName}`}
              onChange={setKey}
              readOnly={!getPermissionWrapper(`design.elements.${elemName}.elementType`).write}
              type={getPermissionType(`design.elements.${elemName}.elementType`, 'text')}
              style={!getPermissionWrapper(`design.elements.${elemName}.elementType`).write ? {backgroundColor: colorBG} : {}}
              parentProps={parentProps}
            />
          </td>
          <td>
            <input
              className={styles['entry-copy']}
              type={'button'}
              value={translate('elementCopy', language)}
              onClick={() => {copyElement(el[0])}}
            />
          </td>
          <td>
            <input className={styles['value-input-button']} type={'button'} value={translate('elementDelete', language)} onClick={() => {deleteElement(elemName)}}/>
          </td>
          <td/>
        </tr>
      )
      rows.push(
        <tr className={styles["table-row"]} key={`maintitle-${el[0]}`}>
          <td>
            {translate('elementCaption', language)}
          </td>
          <td/>
          <td>
            <InputString path={`design.elements.${elemName}.maintitle`} parentProps={parentProps}/>
          </td>
          <td/>
          <td/>
          <td/>
        </tr>
      )

      rows.push(
        <tr className={styles["table-row"]} key={`type-${el[0]}`}>
          <td>
            {translate('elementType', language)}
          </td>
          <td/>
          <td>
            <InputDropdown
              path={`design.elements.${elemName}.elementType`}
              valueList={elementTypes}
              readOnly={!getPermissionWrapper(`design.elements.${elemName}.elementType`).write}
              type={getPermissionType(`design.elements.${elemName}.elementType`, 'text')}
              parentProps={parentProps}
            />
          </td>
          <td/>
          <td/>
          <td/>
        </tr>
      )

      rows.push(
        <tr className={styles["table-row"]} key={`useTemplate-${el[0]}`}>
          <td>
            {translate('useTemplate', language)}
          </td>
          <td/>
          <td>
            <InputDropdown
              path={`design.elements.${elemName}.useTemplate`}
              valueList={Object.keys(designs?.templatesElements ?? {})}
              readOnly={!getPermissionWrapper(`design.elements.${elemName}.useTemplate`).write}
              type={getPermissionType(`design.elements.${elemName}.useTemplate`, 'text')}
              parentProps={parentProps}
            />
          </td>
          <td/>
          <td/>
          <td/>
        </tr>
      )

      rows.push(
        <tr className={styles["table-row"]} key={`templateName-${el[0]}`}>
          <td>
            {translate('templateName', language)}
          </td>
          <td/>
          <td>
            <InputString path={`design.elements.${elemName}.templateName`} parentProps={parentProps}/>
          </td>
          <td/>
          <td/>
          <td/>
        </tr>
      )

      rows.push(
        <tr className={styles["table-row"]} key={`move-${el[0]}`}>
          <td>
            {translate('elementMove', language)}
          </td>
          <td/>
          <td>
            <InputDropdown
              path={`design.elements.${elemName}.move`}
              valueList={elementMoves}
              readOnly={!getPermissionWrapper(`design.elements.${elemName}.move`).write}
              type={getPermissionType(`design.elements.${elemName}.move`, 'text')}
              parentProps={parentProps}
            />
          </td>
          <td/>
          <td/>
          <td/>
        </tr>
      )

      getAdditionalKeys(elemConfig.elementType).forEach(({key, ifFilled, values, allowOther, fieldType}) => {
        rows.push(
          (!ifFilled || !(["", undefined, false, 'false'].includes(retrieveKey(elemConfig, ifFilled))))
          ?
            <tr className={styles["table-row"]} key={`${key}-${el[0]}`}>
            <td>
              {translate(key, language)}
            </td>
            <td/>
            <td>
              {
                key === 'goTo' || values
                  ?
                  <InputDropdown
                    path={`design.elements.${elemName}.${key}`}
                    valueList={key === 'goTo' ? ['previousScreen', ...pageNames] : (values ?? [])}
                    allowOther={allowOther}
                    readOnly={!getPermissionWrapper(`design.elements.${elemName}.${key}`).write}
                    type={getPermissionType(`design.elements.${elemName}.${key}`, 'text')}
                    parentProps={parentProps}
                  />
                  :
                  renderSwitchElem(fieldType ?? "string", elemName, key, parentProps)
              }
              </td>
              <td/>
              <td/>
            <td/>
          </tr>
          : null
        )
      })

      availableKeysCSS.forEach((key) => {
        rows.push(
          <tr className={styles["table-row"]} key={`${key}-${el[0]}`}>
            <td>
              {key}
            </td>
            <td>
              <input className={styles['value-input-button']} type={'button'} value={"Delete"} onClick={() => {deleteKey(elemName, key)}}/>
            </td>
            <td>
              <InputString path={`design.elements.${elemName}.css.${key}`} parentProps={parentProps}/>
            </td>
            <td/>
            <td/>
            <td/>
          </tr>
        )
      })

      rows.push(
        <tr className={styles["table-row"]} key={`newType-${el[0]}`}>
          <td>
            <input
              className={styles['value-input-string']}
              placeholder={translate('elementNewKey', language)}
              id={`newKey-${el[0]}`}
              // value={newKey}
              // onChange={(e) => setNewKey(e.target.value)}
              onKeyDown={(e) => {addNewKeyWrapper(e, elemName)}}
            />
          </td>
          <td>
            <input className={styles['value-input-button']} type={'button'} value={"Add"} onClick={() => {addNewKeyButton(`newKey-${el[0]}`, elemName)}}/>
          </td>
          <td/>
          <td/>
          <td/>
          <td/>
        </tr>
      )


      return rows;
    });

    elements.push([<tr key={`new-hr`}><td><hr/></td></tr>,
      <tr className={styles["table-row"]} key={`new-name`}>
        <td>
          <input
            className={styles['value-input-string']}
            id={`newElement`}
            placeholder={translate('elementNew', language)}
            onKeyDown={(e) => {addNewElementWrapper(e)}}
          />
        </td>
        <td>
          <input className={styles['value-input-button']} type={'button'} value={"Add"} onClick={() => {addNewElementButton(`newElement`)}}/>
        </td>
        <td>
          {
            navigator.userAgent.includes('Firefox')
              ? null
              : <input
                className={styles['entry-paste']}
                type={'button'}
                value={translate('elementPaste', language)}
                onClick={() => {pasteElement()}}
              />
          }
        </td>
        <td/>
        <td/>
        <td/>
      </tr>
    ])
    return elements;
  }

  const postConfig = async () => {
    if (!designs || !design || !stageName || timeoutView || missingURLs.length > 0) {
      return;
    }
    const designsValidate = _.cloneDeep(designs);
    let templatesValid = true;
    let elementsValid = true;
    designsValidate!.configs[stageName] = design;
    [designsValidate.templates, templatesValid] = registerTemplates(designsValidate);
    [designsValidate.templatesElements, elementsValid] = registerElements(designsValidate);
    if (!templatesValid) {
      window.alert("The page-template leads to a cyclic dependency, or the template-name is already used. Please correct this.")
      return;
    }
    if (!elementsValid) {
      window.alert("One of the element-template leads to a cyclic dependency, or its template-name is already used. Please correct this.")
      return;
    }
    const confFilled = fillConfig(design, designsValidate.templates, designsValidate.templatesElements);
    const {status, errorMsg} = validateDesign(confFilled, pageNames);
    if (!status) {
      window.alert(errorMsg);
      return;
    }
    const missingNames = await validateURLs(confFilled);
    if (missingNames.length > 0) {
      setMissingURLs(missingNames);
      return;
    }
    designs!.configs[stageName] = design;

    designs.templates = designsValidate.templates;
    designs.templatesElements = designsValidate.templatesElements;
    setDesignFilled(confFilled);


    setLoading(true);
    const data = await postDesign(designs, jwt)
    setLoading(false);
    if (data.status !== 200) {
      return;
    }
    setTimeoutView(true);
    setTimeout(() => {setTimeoutView(false)}, 1000);
  }

  const getPermissionWrapper = (e: string) => {
    if (!designs || !design) {
      return {read: false, write: false};
    }
    return getPermission(e, designs, design, permissionDict, designs.userPermission);
  }

  const getPermissionType = (e: string, type: string) => {
    if (!designs || !design) {
      return type;
    }
    const perm = getPermission(e, designs, design, permissionDict, designs.userPermission);
    if (!perm.read) {
      return 'password'
    }
    return type
  }


  useEffect(() => {
    const getDesigns = async() => {
      if (!designTag) {
        return;
      }
      const r = await fetchDesign(designTag, jwt);
      if (!r) {
        return;
      }
      const conf = r.configs?.[stageName ?? ''];
      const pageNames = Object.keys(r.configs).sort();
      const [templates, _t] = registerTemplates(r);
      const [elements, _e] = registerElements(r);
      const confFilled = fillConfig(conf, templates, elements);
      setDesign(conf);
      setDesignFilled(confFilled);
      setDesigns(r);
      setPageNames(pageNames);
      languageStore = require(`../../resources/languages/${language}.json`)
    }
    getDesigns();
  }, [])

  const elemIsInstance = (Object.values(design?.design?.elements ?? {})).some((el) => ![undefined, null, ""].includes(el.useTemplate));

  const parentProps: {
    getPermissionWrapper: (e: string) => ({ read?: boolean; write?: boolean });
    design: AppConfig | undefined;
    designs: AppConfigs | undefined;
    designFilled: AppConfig | undefined;
    setter: (baseKey: string, val: any) => void;
    getPermissionType: (e: string, type: string) => (string);
    missingURLs: string[];
    setMissingURLs: React.Dispatch<React.SetStateAction<string[]>>;
    deleteKey: (path: string) => void;
    designTag: string;
    translate: ((e: string) => string);
    isInstance: boolean;
  } = {
    setter: setVal,
    design,
    designs,
    designFilled,
    getPermissionWrapper,
    getPermissionType,
    missingURLs: missingURLs,
    setMissingURLs: setMissingURLs,
    deleteKey: deleteGeneralKey,
    designTag: designTag ?? "",
    translate: ((e: string) => translate(e, language)),
    isInstance: (![undefined, null, ""].includes(design?.settings.useTemplate)) || elemIsInstance,
  }

  return (
    <div className={stylesGeneric["page"]}>
      <Background/>
      <div className={stylesGeneric["settings-button-row"]}>
        <div className={stylesGeneric["settings-button"]}>
          <HomeButton/>
        </div>
        <div className={stylesGeneric["settings-button"]}>
          <SaveButton onClick={postConfig}/>
        </div>
        <div className={stylesGeneric["settings-button"]}>
          <button
            className={stylesGeneric['generic-button-wrapper']}
            onClick={() => {
              window.open(`${window.location.href}/preview`, '_blank', 'toolbar=0,location=0,menubar=0')
            }}>
            PREVIEW
          </button>
        </div>
        <div className={stylesGeneric["settings-button"]}>
          <button
            className={stylesGeneric['generic-button-wrapper']}
            onMouseDown={(e) => openTab(e, `/generateConfigPermission/${designTag}/${stageName}`)}
          >
            <img
              className={stylesGeneric['generic-button']}
              src={img_perm}
              alt={""}
            />
          </button>
        </div>

        <div className={stylesGeneric["settings-button-spacer"]}/>
        <div className={stylesGeneric["settings-button"]}>
          <button
            className={stylesGeneric['generic-button-wrapper']}
            onMouseDown={(e) => openNextPage(e, 0)}
          >
            <FontAwesomeIcon className={stylesGeneric['generic-button']} icon={faAnglesLeft}/>
          </button>
        </div>
        <div className={stylesGeneric["settings-button"]}>
          <button
            className={stylesGeneric['generic-button-wrapper']}
            onMouseDown={(e) => openNextPage(e, -1)}
          >
            <FontAwesomeIcon className={stylesGeneric['generic-button']} icon={faAngleLeft}/>
          </button>
        </div>
        <div className={stylesGeneric["settings-button"]}>
          <button
            className={stylesGeneric['generic-button-wrapper']}
            onMouseDown={(e) => openNextPage(e, 1)}
          >
            <FontAwesomeIcon className={stylesGeneric['generic-button']} icon={faAngleRight}/>
          </button>
        </div>
      </div>
      <div className={stylesGeneric["content-wrapper"]}>
        <div className={stylesGeneric["page-wrapper"]}>
          <div className={styles["title-row"]}>
            <div className={styles["title-cell"]}>
              <h3 className={styles["title-designtag"]}>
                {designTag}
              </h3>
            </div>
            <div className={styles["title-cell"]}>
              <input
                className={styles['page-title-input']}
                value={pageRename}
                onChange={(e) => setPageRename(e.target.value)}
                onKeyDown={renamePage}
              />
            </div>
          </div>
          <div className={styles['table-cell-comment']}>
            <InputString placeholder={'Comment...'} path={'design.comment'} parentProps={parentProps}/>
          </div>
          <table>
            <thead>
            </thead>
            <tbody>

            <tr className={styles["table-row"]}>
              <td className={styles['text-cell-empty']}>
              </td>
              <td/>
              <td/>
              <td/>
              <td/>
              <td/>
            </tr>

            <tr>
              <td className={styles['text-cell']}>
                {translate('backgroundResolution', language)}
              </td>
              <td>
                {translate('backgroundResolutionWidth', language)}
              </td>
              <td className={styles['value-cell']}>
                <div className={styles['value-input-row']}>
                  <InputNumber path={'design.backgroundSize.width'} parentProps={parentProps}/>
                </div>
              </td>
              <td>
                {translate('backgroundResolutionHeight', language)}
              </td>
              <td className={styles['value-cell']}>
                <div className={styles['value-input-row']}>
                  <InputNumber path={'design.backgroundSize.height'} parentProps={parentProps}/>
                </div>
              </td>
              <td>
                {getRatio('current')}
              </td>
            </tr>

            <tr className={styles["table-row"]}>
              <td className={styles['text-cell']}>
                {translate('backgroundImage', language)}
              </td>
              <td>
                {translate('backgroundImageString', language)}
              </td>
              <td className={styles['value-cell']}>
                <div className={styles['value-input-row']}>
                  <InputString placeholder={getDescriptionText(design?.design?.backgroundType)}
                               path={'design.background'} parentProps={parentProps}/>
                </div>
              </td>
              <td>
                {translate('backgroundImageType', language)}
              </td>
              <td className={styles['value-cell']}>
                <div className={styles['value-input-row']}>
                  <InputDropdown
                    path={`design.backgroundType`}
                    valueList={imageTypes}
                    readOnly={!getPermissionWrapper('design.backgroundType').write}
                    type={getPermissionType('design.backgroundType', 'text')}
                    parentProps={parentProps}
                  />
                </div>
              </td>
              <td>
                {translate('backgroundImageBorder', language)}
                <input
                  className={styles['value-input-boolean']}
                  type='checkbox'
                  checked={design?.design?.backgroundSize?.hasBorder ?? false}
                  onChange={(e) => setVal('design.backgroundSize.hasBorder', e.target.checked)}
                  readOnly={!getPermissionWrapper('design.backgroundSize.hasBorder').write}
                  style={!getPermissionWrapper('design.background').write ? {opacity: 0.5} : {}}
                />
              </td>
            </tr>
            {
              design?.design?.backgroundSize?.hasBorder
                ?
                <tr className={styles["table-row"]}>
                  <td className={styles['text-cell']}>
                    {translate('backgroundBorderSize', language)}
                  </td>
                  <td>
                    {translate('backgroundBorderWidth', language)}
                  </td>
                  <td className={styles['value-cell']}>
                    <div className={styles['value-input-row']}>
                      <InputNumber path={'design.backgroundSize.sectionWidth'} parentProps={parentProps}/>
                    </div>
                  </td>
                  <td>
                    {translate('backgroundBorderHeight', language)}
                  </td>
                  <td className={styles['value-cell']}>
                    <div className={styles['value-input-row']}>
                      <InputNumber path={'design.backgroundSize.sectionHeight'} parentProps={parentProps}/>
                    </div>
                  </td>
                  <td/>
                </tr>
                :
                null
            }
            {
              design?.design?.backgroundSize?.hasBorder
                ?
                <tr className={styles["table-row"]}>
                  <td className={styles['text-cell']}>
                    {translate('backgroundBorderOrigin', language)}
                  </td>
                  <td>
                    {translate('backgroundBorderX', language)}
                  </td>
                  <td className={styles['value-cell']}>
                    <div className={styles['value-input-row']}>
                      <InputNumber path={'design.backgroundSize.sectionX'} parentProps={parentProps}/>
                    </div>
                  </td>
                  <td>
                    {translate('backgroundBorderY', language)}
                  </td>
                  <td className={styles['value-cell']}>
                    <div className={styles['value-input-row']}>
                      <InputNumber path={'design.backgroundSize.sectionY'} parentProps={parentProps}/>
                    </div>
                  </td>
                  <td/>
                </tr>
                :
                null
            }

            {
              design?.design?.backgroundSize?.hasBorder
                ?
                <tr className={styles["table-row"]}>
                  <td className={styles['text-cell']}>
                    {translate('backgroundBorderAdditional', language)}
                  </td>
                  <td>
                    {translate('backgroundBorderScalingMode', language)}
                  </td>
                  <td className={styles['value-cell']}>
                    <InputDropdown
                      path={`design.backgroundSize.scaleMode`}
                      valueList={getScalingModes()}
                      readOnly={!getPermissionWrapper('design.backgroundSize.scaleMode').write}
                      type={getPermissionType('design.backgroundSize.scaleMode', 'text')}
                      defaultVal={'contain'}
                      parentProps={parentProps}
                    />
                  </td>
                  <td/>
                  <td/>
                  <td/>
                </tr>
                :
                null
            }

            <tr className={styles["table-row"]}>
              <td className={styles['text-cell-empty']}>
              </td>
              <td/>
              <td/>
              <td/>
              <td/>
              <td/>
            </tr>

            <tr className={styles["table-row"]}>
              <td className={styles['text-cell']}>
                {translate('backgroundResolutionWide', language)}
              </td>
              <td>
                {translate('backgroundResolutionWidth', language)}
              </td>
              <td>
                <div className={styles['value-input-row']}>
                  <InputNumber path={'design.backgroundBounds.maxWidth'} parentProps={parentProps}/>
                </div>
              </td>
              <td>
                {translate('backgroundResolutionHeight', language)}
              </td>
              <td>
                <div className={styles['value-input-row']}>
                  <InputNumber path={'design.backgroundBounds.maxHeight'} parentProps={parentProps}/>
                </div>
              </td>
              <td>
                {getRatio('max')}
              </td>
            </tr>

            <tr className={styles["table-row"]}>
              <td className={styles['text-cell']}>
                {translate('backgroundResolutionNarrow', language)}
              </td>
              <td>
                {translate('backgroundResolutionWidth', language)}
              </td>
              <td>
                <div className={styles['value-input-row']}>
                  <InputNumber path={'design.backgroundBounds.minWidth'} parentProps={parentProps}/>
                </div>
              </td>
              <td>
                {translate('backgroundResolutionHeight', language)}
              </td>
              <td>
                <div className={styles['value-input-row']}>
                  <InputNumber path={'design.backgroundBounds.minHeight'} parentProps={parentProps}/>
                </div>
              </td>
              <td>
                {getRatio('min')}
              </td>
            </tr>

            <tr className={styles["table-row"]}>
              <td className={styles['value-cell']}>
                {translate('backgroundResolutionGoto', language)}
              </td>
              <td/>
              <td>
                <InputDropdown
                  path={`design.backgroundBounds.goTo`}
                  valueList={pageNames}
                  readOnly={!getPermissionWrapper('design.backgroundBounds.goTo').write}
                  type={getPermissionType('design.backgroundBounds.goTo', 'text')}
                  parentProps={parentProps}
                />
              </td>
              <td/>
              <td/>
              <td/>
            </tr>

            <tr className={styles["table-row"]}>
              <td className={styles['text-cell-empty']}>
              </td>
              <td/>
              <td/>
              <td/>
              <td/>
              <td/>
            </tr>

            <tr className={styles["table-row"]}>
              <td className={styles['value-cell']}>
                {translate('screenOrientation', language)}
              </td>
              <td>
                {translate('screenOrientationLock', language)}
              </td>
              <td>
                <InputDropdown
                  path={`design.orientation.lock`}
                  valueList={['true', 'false']}
                  readOnly={!getPermissionWrapper('design.orientation.lock').write}
                  type={getPermissionType('design.orientation.lock', 'text')}
                  booleanDefault={'true'}
                  parentProps={parentProps}
                />
              </td>
              <td>
                {translate('screenOrientationValue', language)}
              </td>
              <td>
                <InputDropdown
                  path={`design.orientation.value`}
                  valueList={getOrientations()}
                  readOnly={!getPermissionWrapper('design.orientation.value').write}
                  type={getPermissionType('design.orientation.value', 'text')}
                  defaultVal={'portrait'}
                  parentProps={parentProps}
                />
              </td>
              <td/>
            </tr>

            <tr className={styles["table-row"]}>
              <td className={styles['value-cell']}>
                {translate('scroll', language)}
              </td>
              <td>
                {translate('scrollX', language)}
              </td>
              <td>
                <InputCheckbox
                  path={'design.scroll.scrollX'}
                  parentProps={parentProps}
                />
              </td>
              <td>
                {translate('scrollY', language)}
              </td>
              <td>
                <input
                  className={styles['value-input-boolean']}
                  type='checkbox'
                  checked={design?.design?.scroll?.scrollY ?? false}
                  onChange={(e) => setVal('design.scroll.scrollY', e.target.checked)}
                  readOnly={!getPermissionWrapper('design.scroll.scrollY').write}
                  style={!getPermissionWrapper('design.background').write ? {opacity: 0.5} : {}}
                />
              </td>
              <td/>
            </tr>

            <tr className={styles["table-row"]}>
              <td className={styles['text-cell']}>
                {translate('copyScreenDesign', language)}
              </td>
              <td/>
              <td className={styles['value-cell']}>
                <div className={styles['value-input-row']}>
                  {/*<InputDropdown*/}
                  {/*  path={`design.template`}*/}
                  {/*  valueList={pageNames}*/}
                  {/*  readOnly={!getPermissionWrapper('design.template').write}*/}
                  {/*  type={getPermissionType('design.template', 'text')}*/}
                  {/*  parentProps={parentProps}*/}
                  {/*/>*/}
                  <Dropdown
                    currentVal={fetchValueAlternative(`design.template`, design, designFilled) ?? ""}
                    valueList={pageNames}
                    onChange={(val: string) => {
                      setVal('design.template', val);
                      copyTemplate(val)
                    }}
                    readOnly={!getPermissionWrapper('design.template').write}
                    type={getPermissionType('design.template', 'text')}
                  />
                </div>
              </td>
              <td/>
              <td/>
              <td/>
            </tr>


            <tr className={styles["table-row"]}>
              <td className={styles['text-cell']}>
                {translate('template', language)}
              </td>
              <td>
                {translate('useTemplate', language)}
              </td>
              <td>
                <InputDropdown
                  path={`settings.useTemplate`}
                  valueList={Object.keys(designs?.templates ?? {})}
                  readOnly={!getPermissionWrapper('settings.useTemplate').write}
                  type={getPermissionType('settings.timeout.useTemplate', 'text')}
                  parentProps={parentProps}
                />
              </td>
              <td>
                {translate('templateName', language)}
              </td>
              <td>
                <InputString placeholder={translate('templateName', language)}
                             path={`settings.templateName`} parentProps={parentProps}/>
              </td>
              <td/>
            </tr>


            <tr className={styles["table-row"]}>
              <td className={styles['text-cell']}>
                {translate('timeout', language)}
              </td>
              <td>
                {translate('timeoutAfter', language)}
              </td>
              <td className={styles['value-cell']}>
                <div className={styles['value-input-row']}>
                  <InputNumber path={'settings.timeout.timer'} scale={1 / 1000} parentProps={parentProps}/>
                </div>
              </td>
              <td>
                {translate('timeoutGoto', language)}
              </td>
              <td className={styles['value-cell']}>
                <div className={styles['value-input-row']}>
                  <InputDropdown
                    path={`settings.timeout.onExpiration`}
                    valueList={['previousScreen', ...pageNames]}
                    readOnly={!getPermissionWrapper('settings.timeout.onExpiration').write}
                    type={getPermissionType('settings.timeout.onExpiration', 'text')}
                    parentProps={parentProps}
                  />
                </div>
              </td>
              <td/>
            </tr>

            <tr className={styles["table-row"]}>
              <td className={styles['text-cell']}>
                {translate('function', language)}
              </td>
              <td/>
              <td className={styles['value-cell']}>
                <div className={styles['value-input-row']}>
                  <InputDropdown
                    path={`settings.callFunction`}
                    valueList={getSiteFunctionList()}
                    readOnly={!getPermissionWrapper('settings.callFunction').write}
                    type={getPermissionType('settings.callFunction', 'text')}
                    parentProps={parentProps}
                  />
                </div>
              </td>
              <td/>
              <td/>
              <td/>
            </tr>

            {
              designFilled?.settings.callFunction === "if"
                ? genFunctionParameters()
                : null
            }
            {
              designFilled?.settings.callFunction === "switch"
                ? genSwitchParameters()
                : null
            }
            {
              designFilled?.settings.callFunction === "copyValues"
                ? genCopyParameters()
                : null
            }
            {
              designFilled?.settings.callFunction === "setValues"
                ? genSetParameters()
                : null
            }
            {
              designFilled?.settings.callFunction === "getRequest"
                ? genGetParameters()
                : null
            }
            {
              designFilled?.settings.callFunction === "genResultURL"
                ? genResParameters()
                : null
            }
            {
              designFilled?.settings.callFunction === "getConfig"
                ? genResParameters()
                : null
            }
            {
              designFilled?.settings.callFunction === "scanQRGeneric"
                ? genQRParameters()
                : null
            }
            {
              designFilled?.settings.callFunction === "postRequest"
                ? genPostParameters()
                : null
            }
            {
              designFilled?.settings.callFunction === "downloadMedia"
                ? genDownloadParameters()
                : null
            }
            {
              designFilled?.settings.callFunction === "checkInternet"
                ? genCheckInternetParameters()
                : null
            }
            {
              designFilled?.settings.callFunction === "parseJSON"
                ? genParseJSONParameters()
                : null
            }
            {
              designFilled?.settings.callFunction === "getUSBSerial"
                ? genResParameters()
                : null
            }
            {
              genEvents()
            }

            {
              genElements()
            }

            </tbody>
          </table>
        </div>
      </div>
      {
        timeoutView
          ? <div className={styles['timeout-view']}/>
          : null
      }
      <LoadingOverlay loading={loading}/>
    </div>
  )
}
