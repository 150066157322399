import {AppConfigs} from "../interfaces/AppConfig";
import {logout} from "./helpers";

export const renewJWT = async (token: string) => {
  const headers: {
    Accept: string;
    "Access-Control-Allow-Origin": string
    "Content-Type"?: string
  } = {
    'Access-Control-Allow-Origin': '*',
    'Accept': "application/json; charset=UTF-8",
    'Content-Type': 'application/json',
  };

  const fetchData: Record<string, any> = {
    method: 'post',
    headers: headers,
  }
  fetchData['body'] = JSON.stringify({access_token: token});


  let url = `${process.env.REACT_APP_URL}/auth/renew`;
  const data = await(fetch(url, fetchData));
  if (data.status !== 200) {
    return '';
  }
  const res = await data.json();
  if (Object.keys(res).includes('access_token')) {
    const token = res.access_token;

    const now = new Date();
    const time = now.getTime();
    const expireTime = time + res.expires;
    now.setTime(expireTime);
    document.cookie = `jwt=${token};expires=${now.toUTCString()};path=/`;
    return res['access_token'];
  }
  return '';
}

export const fetchWrapper = async (url: string, jwt: string, method: string, body?: string) => {
  const headers: {
    Authorization: string;
    Accept: string;
    "Access-Control-Allow-Origin": string
    "Content-Type"?: string
  } = {
    'Access-Control-Allow-Origin': '*',
    'Accept': "application/json; charset=UTF-8",
    'Authorization': 'Bearer ' + jwt,
  };
  if (method !== 'get') {
    headers['Content-Type'] = 'application/json';
  }

  const fetchData: Record<string, any> = {
    method: method,
    headers: headers,
  }
  if (body) {
    fetchData['body'] = body;
  }


  const data = await(fetch(url, fetchData));

  if (data.status !== 200) {
    try {
      const resp = await data.json();
      const text: string = resp['detail'];
      if (text && text.length > 0) {
        window.alert(text);
      }
    } catch (e) {

    }
  }
  return data;
}

export const fetchDesign = async (
  designTag: string,
  jwt: string
) => {
  let url = `${process.env.REACT_APP_URL}/designTag/${designTag}`;
  const data = await(fetch(url, {
    method: "get",
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Accept': "application/json; charset=UTF-8",
      'Authorization': 'Bearer ' + jwt,
    },
  }));
  if (data.status === 401) {
    logout();
    return;
  }
  if (data.status !== 200) {
    return;
  }
  const r: AppConfigs = await data.json();
  return r;
}

export const fetchLocations = async (
  names: string[],
) => {
  let url = `${process.env.REACT_APP_URL}/media/locations/${names}`;
  const data = await(fetch(url, {
    method: "get",
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Accept': "application/json; charset=UTF-8",
    },
  }));
  if (data.status === 401) {
    logout();
    return;
  }
  if (data.status !== 200) {
    return;
  }
  const r: Record<string, {alternateName: string, loc: string[]}> = await data.json();
  return r;
}

export const postDesign = async (
  designs: AppConfigs,
  jwt: string,
  isNew: boolean = false
) => {
  let url = `${process.env.REACT_APP_URL}/design`;
  const data = await (fetch(url, {
    method: "post",
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Accept': "application/json; charset=UTF-8",
      'Authorization': 'Bearer ' + jwt,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      designTag: designs.designTag,
      configs: designs?.configs,
      settings: designs?.settings,
      templates: designs.templates,
      templatesElements: designs.templatesElements,
      language: designs?.language ?? {},
      permission: designs?.permission ?? {},
      isNew: isNew
    })
  }))
  if (data.status === 401) {
    logout();
    return data;
  }
if (data.status !== 200) {
  try {
    const json = await data.json()
    if (json.detail && json.detail !== "" && typeof json.detail === 'string') {
      window.alert(json.detail)
    }
  } catch (e) {
    // pass
  }
}
  return data;
}

export const deleteDesign = async (
  designTag: string,
  jwt: string
) => {
  let url = `${process.env.REACT_APP_URL}/design`;
  const data = await (fetch(url, {
    method: "delete",
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Accept': "application/json; charset=UTF-8",
      'Authorization': 'Bearer ' + jwt,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      designTag: designTag,
    })
  }))
  if (data.status === 401) {
    logout();
    return;
  }
  return;
}

export const downloadFile = (data: object, designName: string) => {
  const blob = new Blob([JSON.stringify(data)], {type: 'text/json'});
  const a = document.createElement('a');
  a.download = `${designName}.json`;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true
  });
  a.dispatchEvent(clickEvt);
  a.remove();
}

export const downloadCSV = (data: string[][], fileName: string) => {
  const blob = new Blob([data.map(e => e.join(",")).join('\n')], {type: 'text/csv'});
  const a = document.createElement('a');
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true
  });
  a.dispatchEvent(clickEvt);
  a.remove();
}
